#canvas{
    border-color: #212529;
    border-style: solid;
    
}
a.disponible {
    background-color: #198754;
    cursor: pointer;
}

a.ocupado {
    background-color: #dc3545;
}

div.ocupado {
    background-color: #dc3545;
}

div.OCUPADO {
    background-color: #dc3545;
}

div.mesaselecion {
    background-color: #6c757d;
    cursor: pointer;
}

div.mesaocupado {
    background-color: #dc3545;
    cursor: not-allowed;
}

div.reservado {
    background-color: #ffc107;
    cursor: not-allowed;
}

div.mesadisponible {
    background-color: #198754;
    cursor: pointer;
}

div.mesareserva {
    background-color: #ffc107;
    cursor: not-allowed;
}

a.reservado {
    background-color: #ffc107;
    cursor: not-allowed;
}

a.seleccionado {
    background-color: #6c757d;
    cursor: pointer;
}

div.disponible {
    background-color: #198754;
    cursor: pointer;
}

div.seleccionado {
    background-color: #6c757d;
    cursor: pointer;
}

div.esquema {
    cursor: pointer;
}

div.esquema:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 3px 5px -1px rgba(0, 0, 0, 0.4)
}

.btn-warning {
    color: rgb(247, 247, 247);
    background-color: #F58634 !important;
    border-color: #F58634 !important;

}

.btn-outline-danger {
    color: #F58634;
    border-color: #F58634 !important;
}

.btn-primary {
    color: #fff;
    background-color: #3F40E0 !important;
    border-color: #3F40E0 !important;
}

.text-pag {
    --bs-text-opacity: 1;
    color: rgba(var(#3F40E0), var(--bs-text-opacity)) !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: #4655FC !important;
    border-color: #4655FC !important;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #b42e2e !important;
    border-color: #b42e2e !important;
}

.btn-warning:hover {
    color: #fff;
    background-color: #f57418 !important;
    border-color: #f57418 !important;
}

.pagos {
    transition: all .3s ease-in-out;
}

.pagos:hover {
    transform: scale(1.05);
    cursor: pointer;
}

li.agregados {
    cursor: pointer;
}
@media (min-width: 950px) {
    .modal-content {
        border-radius: 15px !important;
    }
}
@media (max-width: 967.98px) {
    .modal-dialog {
        width: 100% !important;
        height: 100% !important;
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw !important;
        max-width: none !important;
        height: 100% !important;
        margin: 0 !important;
    }
    .modal-content {
        border-top: 20px !important;
    }
    .img-evento {
        height: 135px !important;
        width: 100% !important;
    }
}
/*
@media (min-width:780px){
    .img-evento{
        height: 280px;
       
     
    }
}*/

.modal-header {
    background-color: #212529 !important;
    color: white;
    border-top: 20px;
}

.toast {
    background-color: #212529 !important;
}

.toast-header {
    background-color: #212529 !important;
    color: white;
}

.close {
    color: white;
    transition: all .3s ease-in-out;
}

.close:hover {

    color: rgb(253, 252, 249);
}

.modal-backdrop.show {
    opacity: .1;
    filter: alpha(opacity=80);
    /* Para versiones anteriores de IE */
}

.modal-fullscreen {
    width: 100vw !important;
    max-width: none !important;
    height: 100% !important;
    margin: 0 !important;
    border-radius: 0px !important;
}

.modal-fullscreen-md-down {
    padding: 0 !important;
}

.modal-fullscreen {
    padding: 0;
}

.link-light {
    cursor: pointer;
}




/*body {*/
/*scrollbar-width: thin;
    /* "auto" or "thin" */
/*  scrollbar-color: blue orange;
    /* scroll thumb and track */
/*}*/

/*
.section {
    scrollbar-width: thin;
    scrollbar-color: blue orange;
}
.section::-webkit-scrollbar {
    width: 12px;
}
.section::-webkit-scrollbar-track {
    background: orange;
}

.section::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 20px;
    border: 3px solid orange;
}

*/
/*div.seleccionmap{
   // background-color: #198754;
}*/