@mixin set-wizard-color($color) {
  .moving-tab{
    background-color: $color;
  }

  .picture{
    &:hover{
      border-color: $color;
    }
  }

  .choice{
    &:hover,
    &.active{
      .icon{
        border-color: $color;
        color: $color;
      }
    }
  }

  .checkbox input[type=checkbox]:checked + .checkbox-material{
    .check{
      background-color: $color;
    }
  }

  .radio input[type=radio]:checked ~ .check {
    background-color: $color;
  }

  .radio input[type=radio]:checked ~ .circle {
    border-color: $color;
  }
}
